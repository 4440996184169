import { Suspense, lazy, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UnAuthenticated from "./templates/UnAuthenticated";
import { useGetDefaultConfigs } from "./mutations/apps";
import { appKeys } from "./keys/appKeys";
import { useQueryClient } from "@tanstack/react-query";
import LoadingBoundary from "./loading/LoadingBoundary";
import { useGetConfigInstanceId } from "./queries/appInstance";
import LoadingSpinner from "./loading/LoadingSpinner";

import ResizeObserver from "resize-observer-polyfill";

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}
function App() {
  const location = useLocation();
  const instanceId = location.pathname.split("/")[2];

  const [configData, setConfigData] = useState<any>();
  const [params, setParams] = useState<any>(null);

  const { data, isLoading: isDataLoading } = useGetConfigInstanceId(
    { instanceId },
    {
      enabled: location.search.split("?")[1] === undefined,
    }
  );

  useEffect(() => {
    if (!location.search.includes("?configs=") && data) {
      setConfigData(data);
    }
  }, [data, setConfigData, location.search]);

  const queryClient = useQueryClient();
  const { mutate: getRenderData, isLoading } = useGetDefaultConfigs({
    onSuccess(data) {
      setConfigData((prevConfigData: any) => ({
        ...prevConfigData,
        app: data.app,
        data: data.data,
        instanceId,
        result: data.result,
        themes: data.themes,
      }));

      queryClient.invalidateQueries(appKeys.item(instanceId));
    },
  });

  useEffect(() => {
    if (location.search.includes("?configs=")) {
      const queryString = decodeURIComponent(
        escape(atob(location.search.split("?configs=")[1]))
      );
      const result = JSON.parse(queryString);
      const { appKey, configs, appId, authentication, calendars, fonts } =
        result;

      const dataSend = {
        appKey: Number(appId!),
        configs: {
          instanceId,
          configs: configs,
          authenticationId: authentication?.id,
          calendars: calendars,
        },
        instanceId,
        result: result.result,
        themes: result.themes,
      };

      getRenderData(dataSend as any);
      setParams(dataSend);

      setConfigData({
        configs,
        appKey,
        fonts,
        instanceId,
        result: result.result,
        themes: result.themes,
      });
    }
  }, [getRenderData, location.search, instanceId]);
  console.log(configData?.app?.type === "custom_embed", "instanceId");
  if (location.search.split("?")[1] === undefined && isDataLoading)
    return (
      <LoadingSpinner
        classNameBody="flex flex-col items-center justify-center w-full h-screen  text-main-100 dark:text-main-20"
        text="Loading data!"
      />
    );

  const Index = getAppIndexComponent(configData?.appKey! || data?.app?.key);

  const isIndexInvalid = Index === null;
  const isConfigDataInvalid = !configData?.configs;
  const isCustomDataEmpty =
    configData?.app?.type === "custom_embed" &&
    Object.keys(configData?.data ?? {}).length === 0 &&
    Object.keys(configData?.configs ?? {}).length === 0;
  console.log(isCustomDataEmpty, "isCustomDataEmpty");
  if (isIndexInvalid || isConfigDataInvalid || isCustomDataEmpty) {
    return (
      <UnAuthenticated isPreview={!location.search.includes("?configs=")} />
    );
  }

  return (
    <>
      {Index && (
        <LoadingBoundary
          loading={isLoading}
          className="w-full h-screen flex justify-center items-center pointer-events-none"
        >
          <Suspense
            fallback={
              <div className="w-full h-screen  flex justify-center items-center pointer-events-none"></div>
            }
          >
            <Index configsFromLayout={configData} params={params} />
          </Suspense>
        </LoadingBoundary>
      )}
    </>
  );
}
function getAppIndexComponent(appKey: string) {
  if (appKey === undefined) {
    return null;
  }
  try {
    const componentPath = import(`./templates/${appKey}/Index`);
    const indexes = lazy(() => componentPath);
    return indexes;
  } catch (error) {
    console.error("Error loading component:", error);
    return null;
  }
}

export default App;
